import React, { useState, useEffect } from "react";
import { Container } from 'react-bootstrap';
import "animate.css/animate.css";
import FeaturedPropertiesSales from '../featured-properties';
import FeaturedPropertiesRent from '../featured-properties-rent';
import $ from 'jquery'
// import ScrollAnimation from 'react-animate-on-scroll';

const PropertiesSection = (props) => {
  //console.log("props",props);

  // const [animation, setAnimation] = useState(false)
  const [myPropertyValue, setMypropertyValue] = useState(0);
  const [showSales, setshowSales] = React.useState(true)
  const [showRent, setshowRent] = React.useState(false)

  function SalesClick() {
    setshowRent(false)
    if(setshowSales(true)){
        $(".property-slider").fadeIn();
    }
  }
  function RentClick() {
    setshowSales(false)
    if(setshowRent(true)){
        $(".property-slider").fadeIn();
    }
  }
  useEffect(() => {

    function isInViewport(el) {
      let top = el.offsetTop;
      let left = el.offsetLeft;
      let width = el.offsetWidth;
      let height = el.offsetHeight;

      while (el.offsetParent) {
        el = el.offsetParent;
        top += el.offsetTop;
        left += el.offsetLeft;
      }

      return (
        top < (window.pageYOffset + window.innerHeight) &&
        left < (window.pageXOffset + window.innerWidth) &&
        (top + height) > window.pageYOffset &&
        (left + width) > window.pageXOffset
      );

    }

    const box = document.querySelector('.properties');
    document.addEventListener('scroll', handleScroll, {
      passive: true
    });

    function handleScroll() {

      if (isInViewport(box)) {

        /** Here one can write animate.css class for animation **/

        box.classList.add('animate__fadeInUp', 'animate__slower');



      } else {

        box.classList.remove('animate__fadeInUp', 'animate__slower');

      }
    }

    return () => {
      document.addEventListener('scroll', handleScroll);
    }

  }, [])

  return (<React.Fragment>
    <section className="section-properties  d-md-block">
    {/* <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true} > */}
      <Container className="properties">
        <div className="head-sec text-center">
         { props.area === "London and Manchester" ? <h2>Properties for Sale and Rent in {props.area}</h2>
         :<h2>Properties for Sale and Rent in and around {props.area?props.area:'London'}</h2>}
          <ul className="category-links d-flex d-md-flex justify-content-md-center">
          {showSales &&
            <>
            <li className="active">
            <a onClick={SalesClick}  href="javascript:;">Properties for Sale</a></li>
            <li><a onClick={RentClick} href="javascript:;">Properties to rent</a></li>
            </>
          }
          {showRent &&
            <>
            <li>
            <a onClick={SalesClick}  href="javascript:;">Properties for Sale</a></li>
            <li className="active"><a onClick={RentClick} href="javascript:;">Properties to rent</a></li>
            </>
          }
          </ul>
        </div>
        {showSales &&
        <FeaturedPropertiesSales  area={props.area === "London and Manchester" ? "" : props.area} officeId={props.officeId}  />
        }
        {showRent &&
        <FeaturedPropertiesRent area={props.area === "London and Manchester" ? "" : props.area} officeId={props.officeId}  />
        }

      </Container>
      {/* </ScrollAnimation> */}
    </section>

  </React.Fragment>)

}

export default PropertiesSection;